#root {
  background-color: #eaeaea;
  color: #333;
  display: flex;
  flex-direction: column;
}

.App {
  flex-grow: 1;
  text-align: center;
  background-color: #eaeaea;
}

.section {
  text-align: left;
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.privacy-logo {
  height: 35vmin;
}

.footer-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #eaeaea;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #545454;
}

.App-footer {
  background-color: #545454;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(8px + 1vmin);
  color: #eaeaea;
}

.App-link {
  color: #61dafb;
  text-decoration: none !important;
}

.borderedTable {
  border-collapse: collapse;
  width: 100%;
}

th {
  background-color: #162c53;
  color: #eaeaea;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.toTheRight {
  text-align: right;
}

.centerLine {
  text-align: center;
}

#privacyPolicy ul li {
  list-style-type: square;
  padding: 5px;
  font-size: smaller;
}